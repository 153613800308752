import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Tag, Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

import { searchDeliveriesFilter } from 'services/deliveries';
import { COUNTRY_CURRENCY } from 'constants/helper';

import DeliveryStateFormatter from 'containers/Deliveries/common/DeliveryStateFormatter';
import {
  PACKAGING_SHIPPING_ORDERS_TABLE_FILTERS,
  SHIPMENTS_COLUMNS,
  WALLET
} from 'constants/packaging';
import { formatAreaZone } from 'utils/packaging';
import { MAP_DELIVERY_TYPES } from 'constants/Deliveries';
import { getBostaMaterialBusinessCountryCode } from 'common/countries/countries-mapping';
import { exportShippingOrders, getFlyersOrdersCount } from 'services/packaging';
import { checkSmartDate, dates } from 'utils/helpers';

import BRTable from 'components/BRTable/BRTable';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as CodIcon } from 'assets/bosta-icons/cash-packaging-icon.svg';
import { ReactComponent as WalletIcon } from 'assets/bosta-icons/wallet-packaging-icon.svg';
import { ReactComponent as RefundIcon } from 'assets/bosta-icons/refund-packaging-icon.svg';
import { ReactComponent as ClosedEye } from 'assets/bosta-icons/Eye-1.svg';

import './ShippingOrders.less';

const bostaMaterialAccountBusinessId = getBostaMaterialBusinessCountryCode();

const ShippingOrders = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [showCount, setShowCount] = useState(false);
  const [isCountLoading, setIsCountLoading] = useState(false);

  const intl = useIntl();

  const handleOnTrackingNumberClick = ({ _id }) => {
    window.open(`/deliveries/${_id}/details`);
  };

  const formatDeliveries = (deliveries) => {
    const formattedDeliveries = [];
    deliveries?.map((delivery) => {
      const orderArea = formatAreaZone(delivery.dropOffAddress);
      formattedDeliveries.push({
        key: delivery.trackingNumber,
        _id: delivery._id,
        order_id: (
          <span className="br-packaging-shipping-orders__tracking-number-text">
            {delivery.trackingNumber}
          </span>
        ),
        type: MAP_DELIVERY_TYPES.SEND,
        customerInfo: (
          <>
            <div className="br-ellipsis-cell-content">
              {delivery.receiver.fullName
                ? delivery.receiver.fullName
                : `${delivery.receiver.firstName} ${delivery.receiver.lastName}`}
            </div>

            <div className="br-orders__column-subtext caption-lg">
              {delivery.receiver.phone}
            </div>
          </>
        ),
        location: (
          <>
            <div className="br-ellipsis-cell-content">
              {delivery?.dropOffAddress?.city?.name}
            </div>

            {orderArea !== intl.formatMessage({ id: 'common.empty_field' }) && (
              <div className="br-orders__column-subtext caption-lg">
                {orderArea}
              </div>
            )}
          </>
        ),
        cod: delivery.draftOrderInfo?.totalCost ? (
          <div className="br-amount-column__container">
            {Math.sign(delivery.draftOrderInfo.totalCost) === -1 ? (
              <RefundIcon />
            ) : delivery.draftOrderInfo.paymentMethod === WALLET ? (
              <WalletIcon />
            ) : (
              <CodIcon />
            )}

            <div className="br-amount-column__info-container">
              <span className="body-medium">
                {intl.formatMessage(
                  {
                    id: COUNTRY_CURRENCY
                  },
                  {
                    cod: delivery.draftOrderInfo.totalCost
                  }
                )}
              </span>

              <div className="br-amount-column__info-details">
                <span className="caption-lg">
                  {intl.formatMessage({
                    id: `orders.shipment_details.shipment_details_summary.table_cells_labels.${
                      Math.sign(delivery.draftOrderInfo.totalCost) === -1
                        ? 'refund'
                        : delivery.draftOrderInfo.paymentMethod === WALLET
                        ? 'wallet'
                        : 'cash_on_delivery'
                    }`
                  })}
                </span>

                {delivery.draftOrderInfo.paymentMethod === WALLET && (
                  <Tooltip
                    placement={'bottomRight'}
                    arrowPointAtCenter
                    overlayClassName="wallet-tooltip__overlay"
                    title={
                      <div className="wallet-tooltip__container">
                        <div className="wallet-tooltip__header">
                          <span className="wallet-tooltip__header-title">
                            {intl.formatMessage({
                              id: 'packaging_wallet.tooltip.title'
                            })}
                          </span>
                        </div>
                        <span className="wallet-tooltip__header">
                          {intl.formatMessage({
                            id: 'packaging_wallet.tooltip.message'
                          })}
                        </span>
                      </div>
                    }
                  >
                    <InfoCircleFilled />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="br-orders__no-cod-label">
            {intl.formatMessage({
              id: `common.no_cash_on_delivery`
            })}
          </div>
        ),
        status: delivery.state ? (
          <Tag
            className={`${DeliveryStateFormatter.getStateColor(
              delivery
            )} br-packaging-shipping-orders__status-column`}
          >
            {DeliveryStateFormatter.getStateName(delivery)}
          </Tag>
        ) : (
          'N/A'
        ),
        attempts: delivery.attemptsCount,
        date: (
          <>
            {checkSmartDate(
              delivery.updatedAt ? delivery.updatedAt : delivery.createdAt
            )}
            <div className="br-orders__column-subtext caption-lg">
              {dates(
                delivery.updatedAt ? delivery.updatedAt : delivery.createdAt,
                'h:mm A'
              )}
            </div>
          </>
        )
      });
    });
    return formattedDeliveries;
  };

  const getTotal = ({ currentPage, result, limit }) => {
    if (showCount) {
      return totalCount;
    }

    if (result.length === limit) {
      return currentPage * limit + 1;
    } else {
      return currentPage * limit;
    }
  };

  const getCount = async () => {
    setIsCountLoading(true);
    try {
      const { count } = await getFlyersOrdersCount(filterValues);
      setTotalCount(count);
      setShowCount(true);
    } catch (error) {
      notify(error.message);
    }
    setIsCountLoading(false);
  };

  const getOrdersData = async ({
    page,
    limit,
    trackingNumbers,
    mobilePhones,
    ...filterData
  }) => {
    setIsTableLoading(true);

    try {
      const payload = {
        businessId: [bostaMaterialAccountBusinessId],
        trackingNumbers,
        mobilePhones,
        ...filterData
      };

      const { deliveries } = await searchDeliveriesFilter({
        pageLimit: limit,
        pageNumber: page,
        ...payload
      });

      setShowCount(false);
      setIsTableLoading(false);
      setFilterValues(payload);

      return {
        list: formatDeliveries(deliveries),
        total: getTotal({ currentPage: page, result: deliveries || [], limit })
      };
    } catch (error) {
      notify(error.message);
    }
    setIsTableLoading(false);
  };

  const handleOnExportClick = async () => {
    try {
      const { message } = await exportShippingOrders({
        ...filterValues
      });
      notify(message, 'success');
    } catch (error) {
      notify(error.message);
    }
  };

  const renderTableTitle = () => (
    <div className="br-packaging-orders__count">
      {intl.formatMessage(
        {
          id: 'packaging.orders_tab.shipping_orders.table.title'
        },
        {
          count: showCount ? (
            totalCount
          ) : (
            <BRButton
              label={intl.formatMessage({
                id: 'pickups.pickup_request.show_count'
              })}
              loading={isCountLoading}
              prefixIcon={<ClosedEye />}
              onClick={getCount}
            />
          ),
          span: (children) => <span className="body-medium">{children}</span>
        }
      )}
    </div>
  );

  return (
    <div className="br-packaging-orders__shipping-orders-container">
      <BRTable
        className="br-shipping-orders__table"
        title={renderTableTitle()}
        isLoading={isTableLoading}
        columns={SHIPMENTS_COLUMNS}
        pageSizeOptions={[50, 100, 200]}
        pageLimit={50}
        listFunction={getOrdersData}
        tableFilters={PACKAGING_SHIPPING_ORDERS_TABLE_FILTERS}
        onRowClick={handleOnTrackingNumberClick}
        exportListFileFunction={handleOnExportClick}
        hideFilterButton={false}
        showSearch={true}
      />
    </div>
  );
};

export default ShippingOrders;
